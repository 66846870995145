<template>
  <div class="top">
    <main>
      <TopVisual />
      <CatchCopy />
      <ArtistList />
      <SliderImg />
      <ProgramList />
      <ScheduleList />
      <EventTicket />
      <EventMap />
      <OrganizerCredit />
      <ArchiveFes />
    </main>
    <SiteFooter />
    <SiteHeader />
  </div>
</template>

<script>
import TopVisual from "@/components/TopVisual.vue";
import CatchCopy from "@/components/CatchCopy.vue";
import ArtistList from "@/components/ArtistList.vue";
import SliderImg from "@/components/SliderImg.vue";
import ProgramList from "@/components/ProgramList.vue";
import ScheduleList from "@/components/ScheduleList.vue";
import EventTicket from "@/components/EventTicket.vue";
import EventMap from "@/components/EventMap.vue";
import OrganizerCredit from "@/components/OrganizerCredit.vue";
import ArchiveFes from "@/components/ArchiveFes.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import SiteHeader from "@/components/SiteHeader.vue";
export default {
  name: "SiteTop",
  components: {
    TopVisual,
    CatchCopy,
    ArtistList,
    SliderImg,
    ProgramList,
    ScheduleList,
    EventTicket,
    EventMap,
    OrganizerCredit,
    ArchiveFes,
    SiteFooter,
    SiteHeader,
  },
};
</script>

<style lang="scss">
.top {
  position: relative;
}
main {
  position: relative;
}
.mainimg img {
  width: 100vw;
}
section {
  margin: 5rem 0;
  padding: 3rem 0;
  @include sp {
    margin: 1rem 0;
    padding: 1rem 0;
  }
}
.gray-back {
  background-color: #f4f4f4;
}
</style>
