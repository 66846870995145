<template>
  <div class="catchcopy">
    <section id="section1" class="">
      <figure class="catchcopy-bg">
        <img src="@/assets/img/catchcopy-bg.svg" alt="" />
      </figure>
      <!-- <span class="note">※本イベントは終了しました</span> -->
      <h2 class="center about">別所温泉芸術祭 ‐ZERO‐ とは</h2>
      <div class="container">
        <a
          class="poster-link"
          href="/img/lp.pdf"
          target="_blank"
          rel="noopener noreferrer"
          >チラシを見る ></a
        >
        <div class="pc">
          国内外で活躍するアーティストの現代的な作品と、別所温泉の<br />
          風土や歴史が交錯しながら、この地域の新しい魅力の発信を<br />
          コンセプトにこの芸術祭は立ち上げられました。<br /><br />
          芸術祭のテーマは現在を表す起点という意味を込めて<br />
          -ZERO-としました。過去が語りかけてくれるもの、そして新しい<br />
          未来への息吹。この2つを繋ぐ時間の流れの中で- ZERO-は<br />
          その確かな結び目になっていくことでしょう。<br /><br />
          この地域を訪れる旅人達、そしてこの地域を愛し生活を営む<br />
          人達の創造と交流の場として、別所温泉芸術祭- ZERO -は開幕いたします。
          <div class="artist-profile">
            <figure class="artist-profile-img">
              <img
                src="@/assets/img/artist13.webp"
                alt="山本裕のプロフィール"
              />
            </figure>
            <div class="artist-profile-txt">
              <div>
                <h3>山本 裕</h3>
                <p>アートディレクター/ダンス</p>
                <p>
                  文化庁主催公演新人賞、ダンスプラン賞、全国舞踊コンクール第1位、Outstanding
                  Contemporary Choreographer Award(アメリカ)、Dance Theater
                  Prize(チェコ)など多数受賞。文化庁新進芸術家海外研修員制度にてオランダのスカピーノバレエ団に1年間留学。ヨーロッパやアジアのフェスティバルより招待、ゲスト出演している。国内では瀬戸内国際芸術祭、六本木アートナイト、豊岡演劇祭、都民芸術フェスティバルなどの振付家に選出。オン・ステージ新聞新人振付家ベスト。振付家、ダンサーとしての実績とジャンルを超えた幅広い活躍が評価され、各地の芸術祭のアートディレクターを務める。<br /><a
                    href="https://www.yu-y.art/"
                    >https://www.yu-y.art/</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="sp">
          国内外で活躍するアーティストの現代的な作品と、別所温泉の
          風土や歴史が交錯しながら、この地域の新しい魅力の発信を
          コンセプトにこの芸術祭は立ち上げられました。<br /><br />
          芸術祭のテーマは現在を表す起点という意味を込めて
          -ZERO-としました。過去が語りかけてくれるもの、そして新しい
          未来への息吹。この2つを繋ぐ時間の流れの中で- ZERO-は
          その確かな結び目になっていくことでしょう。<br /><br />
          この地域を訪れる旅人達、そしてこの地域を愛し生活を営む
          人達の創造と交流の場として、別所温泉芸術祭- ZERO -は開幕いたします。
          <div class="artist-profile">
            <figure class="artist-profile-img">
              <img
                src="@/assets/img/artist13.webp"
                alt="山本裕のプロフィール"
              />
            </figure>
            <div class="artist-profile-txt">
              <div>
                <h3>山本 裕</h3>
                <p>アートディレクター/ダンス</p>
                <p>
                  文化庁主催公演新人賞、ダンスプラン賞、全国舞踊コンクール第1位、Outstanding
                  Contemporary Choreographer Award(アメリカ)、Dance Theater
                  Prize(チェコ)など多数受賞。文化庁新進芸術家海外研修員制度にてオランダのスカピーノバレエ団に1年間留学。ヨーロッパやアジアのフェスティバルより招待、ゲスト出演している。国内では瀬戸内国際芸術祭、六本木アートナイト、豊岡演劇祭、都民芸術フェスティバルなどの振付家に選出。オン・ステージ新聞新人振付家ベスト。振付家、ダンサーとしての実績とジャンルを超えた幅広い活躍が評価され、各地の芸術祭のアートディレクターを務める。<br /><a
                    href="https://www.yu-y.art/"
                    >https://www.yu-y.art/</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CatchCopy",
};
</script>

<style lang="scss" scoped>
.catchcopy {
  position: relative;
  overflow: hidden;
}

.note {
  color: #f00;
}

.container {
  text-align: center;
}

.artist-profile {
  display: flex;
  align-items: stretch;
  height: fit-content;
  margin-top: 4rem;
  border: 2px solid #000;
  overflow: hidden;
  @include sp {
    width: 80%;
    margin: 0 auto;
    margin-top: 3em;
    flex-direction: column;
  }
}
.artist-profile-img {
  width: 100%;
  flex-basis: 30%;
  margin: 0;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
}
.artist-profile-txt {
  display: flex;
  align-items: center;
  flex-basis: 70%;
  width: 100%;
  padding: 1.5rem;
  text-align: left;
  background: transparent;
  h3 {
    font-weight: 600;
    margin-bottom: 0;
  }
}

.about {
  font-weight: bold;
  font-size: 35px;
  @include sp {
    font-size: 2.3rem;
  }
}

.poster-link {
  position: relative;
  display: inline-block;
  margin-bottom: 4rem;
  padding: calc(1.5rem - 12px) 3rem 1.5rem;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #000;
  background: #b0e0e2;
  &::before {
    position: absolute;
    top: -6px;
    left: -6px;
    content: "";
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 2px solid #000;
  }
  &:hover {
    padding: calc(1.5rem - 6px) 3rem;
    color: #000;
    &::before {
      top: 0;
      left: 0;
    }
  }
}

.catchcopy-bg {
  position: absolute;
  top: -10vh;
  right: -10vw;
  z-index: -1;
  width: 40vw;
  @include sp {
    width: 80vw;
  }
  > img {
    width: 100%;
    height: auto;
    opacity: 0.4;
  }
}
</style>
